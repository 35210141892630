import React, { useState, useEffect } from "react";
import { IMedia, fileTypes } from "../../interfaces/story";
import { mdiPlay } from "@mdi/js";
import Icon from "@mdi/react";
import { useHistory } from "react-router-dom";
import "./story-media-collage.styles.css";

interface IProps {
  media: IMedia[];
  layout?: "single" | "dashboard";
  storyId?: string;
}

export default function StoryMediaCollage({ media, layout = "dashboard", storyId }: IProps) {
  const [videoThumbnails, setVideoThumbnails] = useState<{ [key: string]: string }>({});
  const history = useHistory();

  // Generate video thumbnails
  useEffect(() => {
    media.forEach((item) => {
      if (item.fileType === fileTypes.VIDEO) {
        const video = document.createElement("video");
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");

        video.onloadedmetadata = () => {
          video.currentTime = 0.1;
        };

        video.onseeked = () => {
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          context?.drawImage(video, 0, 0, canvas.width, canvas.height);
          const thumbnail = canvas.toDataURL("image/jpeg", 0.8);
          setVideoThumbnails((prev) => ({ ...prev, [item.data]: thumbnail }));
        };

        video.src = item.data;
        video.crossOrigin = "anonymous";
        video.load();
      }
    });
  }, [media]);

  // Determine how many images to display and how many are remaining
  const maxDisplayCount = media.length > 4 ? 4 : media.length;
  const displayMedia = media.slice(0, maxDisplayCount);
  const remainingCount = Math.max(0, media.length - maxDisplayCount);

  // Handle media click to navigate to story
  const handleMediaClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (storyId) {
      history.push(`/dashboard/story/${storyId}`);
    }
  };

  // Get the appropriate CSS class based on media count
  const getLayoutClass = () => {
    if (media.length === 1) return "single-image";
    if (media.length === 2) return "two-images";
    if (media.length === 3) return "three-images";
    return "many-images";
  };

  return (
    <div className={`story-media-collage ${getLayoutClass()} ${layout}`}>
      <div className="media-grid">
        {displayMedia.map((item, index) => (
          <div 
            key={`media-${index}`} 
            className={`media-item ${media.length === 1 ? 'centered' : ''} ${(index === maxDisplayCount - 1 && remainingCount > 0) ? 'last-item' : ''}`}
            onClick={handleMediaClick}
          >
            {item.fileType === fileTypes.VIDEO ? (
              <div className="video-thumbnail">
                <img 
                  src={videoThumbnails[item.data] || item.data} 
                  alt={item.name || "Video thumbnail"} 
                />
                <div className="play-button">
                  <Icon path={mdiPlay} size={1} />
                </div>
              </div>
            ) : (
              <img 
                src={item.data} 
                alt={item.name || "Media"} 
              />
            )}
            
            {/* Show remaining count overlay on the last visible item if there are more items */}
            {(index === maxDisplayCount - 1 && remainingCount > 0) && (
              <div className="remaining-count-overlay">
                <span>+{remainingCount}</span>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
