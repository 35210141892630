import React from "react";
import Icon from "@mdi/react";
import {
  mdiDotsHorizontal,
  mdiCameraOutline,
  mdiMovieOutline,
  mdiVolumeHigh,
  mdiCommentTextOutline,
  mdiEyeOutline,
} from "@mdi/js";
import { blockTypes, IStory, IMedia } from "../../interfaces/story";
import { IUserDetails } from "../../interfaces/authentication";
import { useTranslation } from "react-i18next";
import { getStoryDate } from "../../_helper/getStoryDate";
import default_avatar from "../../assets/images/default-avatar.jpg";
import "./story-item.styles.css";
import moment from "moment";
import StoryMediaCollage from "./story-media-collage.component";
import { useHistory, useLocation } from "react-router-dom";

interface IStoryItemProps {
  story: IStory;
  type?: "NEW" | "UNREAD" | "NORMAL";
  onSelect: () => void;
  onAction: (e: React.MouseEvent) => void;
  onFlagClick?: (e: React.MouseEvent) => void;
  flagImage?: string;
  flagText?: string;
  storyComments?: { [key: string]: number };
  getBlockData?: (blocks: any[], storyId: string) => React.ReactNode;
  isShowTime?: boolean;
  layout?: "single" | "dashboard";
}

const StoryItem: React.FC<IStoryItemProps> = ({
  story,
  type = "NORMAL",
  onSelect,
  onAction,
  onFlagClick,
  flagImage,
  flagText,
  storyComments = {},
  getBlockData,
  isShowTime = false,
  layout = "dashboard",
}) => {
  const { t } = useTranslation();
  const featured = story.featured as IUserDetails;
  const history = useHistory();
  const location = useLocation();
  const isTodayPage = location.pathname.includes("/dashboard/stories/1");
  const isSimplifiedLayout = layout === "single" && !isTodayPage;

  const imageBlocks = story.blocks.filter(
    (x) => x.type === blockTypes.IMAGE
  ).length;
  const audioBlocks = story.blocks.filter(
    (x) => x.type === blockTypes.AUDIO
  ).length;
  const videoBlocks = story.blocks.filter(
    (x) => x.type === blockTypes.VIDEO
  ).length;
  const carouselBlocks = story.blocks.filter(
    (x) => x.type === blockTypes.CAROUSEL
  );

  const yearsPassed = new Date().getFullYear() - story.date.startDate.year;
  const isPeriod = story.date.endDate ? true : false;

  const getTimeAgo = (date: Date): string => {
    const now = moment();
    const storyDate = moment(date);
    const diffMinutes = now.diff(storyDate, "minutes");
    const diffHours = now.diff(storyDate, "hours");
    const diffDays = now.diff(storyDate, "days");
    const diffMonths = now.diff(storyDate, "months");
    const diffYears = now.diff(storyDate, "years");

    if (diffMinutes < 1) return "just now";
    if (diffMinutes < 60)
      return `${diffMinutes} ${diffMinutes === 1 ? "minute" : "minutes"} ago`;
    if (diffHours < 24)
      return `${diffHours} ${diffHours === 1 ? "hour" : "hours"} ago`;
    if (diffDays < 30)
      return `${diffDays} ${diffDays === 1 ? "day" : "days"} ago`;
    if (diffMonths < 12)
      return `${diffMonths} ${diffMonths === 1 ? "month" : "months"} ago`;
    return `${diffYears} ${diffYears === 1 ? "year" : "years"} ago`;
  };

  function getAllMediaFromStory(story: IStory): IMedia[] {
    const allMedia: IMedia[] = [];

    story.blocks.forEach((block) => {
      if (block.media) {
        allMedia.push(block.media);
      }
      if (block.multipleMedia && block.multipleMedia.length) {
        allMedia.push(...block.multipleMedia);
      }
    });

    return allMedia;
  }

  const allMedia = getAllMediaFromStory(story);

  const handleStoryClick = () => {
    history.push(`/dashboard/story/${story._id}`);
  };

  return (
    <div
      className={`story-item-container ${
        isSimplifiedLayout ? "simplified-layout" : ""
      }`}
      onClick={handleStoryClick}
    >
      <div className="story-item-content">
        <div className="story-item-header">
          {flagImage && (
            <div
              className="flair"
              onClick={(e) => {
                e.stopPropagation();
                if (onFlagClick) onFlagClick(e);
              }}
            >
              <img alt="flag" src={flagImage} />
            </div>
          )}
          {(flagText || isShowTime) ? 
            <p>
              {flagText ||
                (isShowTime
                  ? getTimeAgo(new Date(story.date.startDate.date))
                  : getDateText(yearsPassed, isPeriod, t))}
            </p>
            : !isSimplifiedLayout ? 
                <p>&nbsp;</p>
                : null
          }
          <div
            className="action-container"
            onClick={(e) => {
              e.stopPropagation();
              onAction(e);
            }}
          >
            <Icon className="action" size={1} path={mdiDotsHorizontal} />
          </div>
        </div>

        {isSimplifiedLayout ? (
          <div className="story-item-info simplified">
            <h3 className="story-item-title">{story.title}</h3>
            <div className="author-info">
              <div className="author-left">
                <img
                  src={featured.avatar || default_avatar}
                  className="user-picture"
                  alt="user"
                />
                <span className="user-name">
                  {`${featured.firstname} ${featured.lastname}`.toUpperCase()}
                </span>
              </div>
              <span className="story-date">
                {getStoryDate(story.date.startDate)}
              </span>
            </div>
          </div>
        ) : (
          <div className="story-item-info">
            <h3 className="story-item-title">{story.title}</h3>
            <img
              src={featured.avatar || default_avatar}
              className="user-picture"
              alt="user"
            />
            <p className="story-item-author">
              <span className="user-name">
                {`${featured.firstname} ${featured.lastname}`}
              </span>
              <span className="story-date">
                {getStoryDate(story.date.startDate)}
                {story.date.endDate && (
                  <span> &nbsp;-&nbsp; {getStoryDate(story.date.endDate)}</span>
                )}
              </span>
            </p>
          </div>
        )}

        {getBlockData && !isSimplifiedLayout && (
          <div className="story-blocks">
            {getBlockData(story.blocks, story._id)}
          </div>
        )}

        {allMedia.length > 0 && (layout !== "single" || isTodayPage) && (
          <div className="story-media-preview">
            <StoryMediaCollage
              media={allMedia}
              layout={layout}
              storyId={story._id}
            />
          </div>
        )}

        {!isSimplifiedLayout && (
          <div className="story-item-footer">
            <div className="toolbar">
              {imageBlocks > 0 && (
                <span title="image">
                  <Icon className="icon" size={0.8} path={mdiCameraOutline} />
                  {imageBlocks}
                </span>
              )}
              {videoBlocks > 0 && (
                <span title="video">
                  <Icon className="icon" size={0.8} path={mdiMovieOutline} />
                  {videoBlocks}
                </span>
              )}
              {audioBlocks > 0 && (
                <span title="audio">
                  <Icon className="icon" size={0.8} path={mdiVolumeHigh} />
                  {audioBlocks}
                </span>
              )}
              {carouselBlocks.length > 0 && (
                <span title="image">
                  <Icon className="icon" size={0.8} path={mdiCameraOutline} />
                  {carouselBlocks[0].multipleMedia.length}
                </span>
              )}
              {storyComments[story._id] > 0 && (
                <span title="comments">
                  <Icon
                    className="icon"
                    size={0.8}
                    path={mdiCommentTextOutline}
                  />
                  {storyComments[story._id]}
                </span>
              )}
            </div>
            <div className="readtime">
              <span title="read time">
                <Icon className="icon" size={0.8} path={mdiEyeOutline} />
                {story.readTime} {t("Min")}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const getDateText = (yearsPassed: number, isPeriod: boolean, t: any) => {
  if (yearsPassed === 0 && !isPeriod) return t("Today");
  if (yearsPassed > 0 && !isPeriod)
    return t("{{var}} years ago, Today", { var: yearsPassed });
  if (yearsPassed === 0 && isPeriod) return t("Starting Today");
  if (yearsPassed > 0 && isPeriod)
    return t("{{var}} years ago, starting today", { var: yearsPassed });
  return "";
};

export default StoryItem;
