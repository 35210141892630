import Icon from '@mdi/react';
import React, { useContext, useEffect, useState, useRef } from 'react';
import instance from '../../api/api';
import { blockTypes, IBlock, IStory } from '../../interfaces/story';
import default_avatar from "../../assets/images/default-avatar.jpg";
import { IUserDetails } from '../../interfaces/authentication';
import moment from 'moment';
import TooltipHelp from '../notifications/tooltiphelp.component';
import StoryFiltersBar, { sortOptions } from './story-filters-bar.component';
import gold_flag from "../../assets/images/bg-flag-gold.svg";
import green_flag from "../../assets/images/bg-flag-green.svg";
import { useHistory, useLocation } from 'react-router-dom';
import { Context } from '../../pages/home.page';
import StoryActionModal from './story-action-modal.component';
import StoryFilters, { IFilters, IRefactoredQueryObject, readTimeOptions } from './story-filters.component';
import { mdiAccount, mdiClose, mdiAccountGroup, mdiPencil, mdiPound, mdiCalendar, mdiEarth, mdiEye, mdiGoogleCirclesCommunities, mdiScriptTextOutline, mdiCheck, mdiMagnify, mdiViewDashboard, mdiViewSequential } from '@mdi/js';
import { IOption } from '../../interfaces/family';
import { countries } from 'countries-list';
import { Spinner } from 'react-bootstrap';
import IFrameDisplay from '../_helper/iframe-display.component';
import { useTranslation } from "react-i18next";
import Masonry from 'react-masonry-css';
import StoryItem from './story-item.component';
import { convertFromHTML } from 'draft-js';
import { ContentState } from 'draft-js';
import { EditorState } from 'draft-js';
import { ReactComponent as IconLifelesson } from "../../assets/images/icon-life_lesson.svg";
import VideoPlayer from '../_helper/video-player.component';

interface IProps {
}

export const newTooltip = {
    title: "What's this?",
    subtitle: "Newly Published",
    text: () => (
        <div style={{textAlign:'center'}}>
            <p className="subtitle">
                <img alt="flag" src={green_flag}/>
            </p>
            <p>Published after your last visit</p>
        </div>
    ),
}

export const unReadTooltip = {
    title: "What's this?",
    subtitle: "Unread story",
    text: () => (
        <div style={{textAlign:'center'}}>
            <p className="subtitle">
                <img alt="flag" src={gold_flag}/>
            </p>            
            <p>You have not read this story</p>
        </div>
    ),
}

export const defaultStoryFilters : IFilters = {
    date: { date: new Date(), year: null, month: null, day: null },

    storiesRelatingTo:[],

    featured:[],
    participants:[],
    storyAuthorCoAuthor:[],

    storyTags:[],
    lifeLessons:[],
    accessGroups:[],

    country:'',
    readTime:'',
    search: '',
    favorites:false,
    readLaterStories:false,
    unreadStories:false,
}

const filterIcons : any = {
    storiesRelatingTo:mdiAccount,
    featured:mdiAccount,
    participants:mdiAccountGroup,
    storyAuthorCoAuthor:mdiPencil,
    date: mdiCalendar,
    storyTags:mdiPound,
    lifeLessons:mdiScriptTextOutline, //TODO: cant find the icon
    accessGroups:mdiGoogleCirclesCommunities,
    country:mdiEarth,
    readTime:mdiEye,
    favorites:mdiCheck,
    readLaterStories:mdiCheck,
    unreadStories:mdiCheck,
    search:mdiMagnify
}

interface IbooleanReadableValues {
    [key: string]: any
    favorites:string,
    readLaterStories:string,
    unreadStories:string,
}

const booleanReadableValues:IbooleanReadableValues = {
    favorites:"Favourites",
    readLaterStories:"Read Later",
    unreadStories:"Unread Stories",
}

export interface ISort {
    field:string
    type:string
}

enum layoutStyle {
    SINGLE = "1",
    DASHBOARD = "2"
}

interface IGenericObject {
    [key: string]:number
}

export default function AllPublishedStories(props: IProps) {
    
    const [ publishedStories, setPublishedStories ] = useState<IStory[]>([]);

    //Tooltips
    const [showNew, setShowNew] = useState<boolean>(false);
    const [showUnRead, setShowUnRead] = useState<boolean>(false);
    const [ selected, setSelected ] = useState<IStory | boolean>(false);

    //Filters
    const [ showFilters, _setShowFilters ] = useState<boolean>(false);
    const [ queryString, setQueryString ] = useState<string>("");
    const [ filtersActive, setFiltersActive ] = useState<boolean>(false);
    const [ stateLoaded, setStateLoaded ] = useState<boolean>(false);

    const filters = useContext(Context).storyFilters;
    const setFilters = useContext(Context).setStoryFilters;

    //sort and search
    const [ sort, setSort ] = useState<sortOptions>(sortOptions.PUBLISHED_DATE);
    const [ sortOption, setSortOption ] = useState<ISort>({field:'createdAt', type:'desc'});

    //pagination
    const [ pageNum, setPageNum ] = useState<number>(0);
    const [ loading, setLoading ] = useState<boolean>(false);
    const [ hasMore, setHasMore] = useState<boolean>(true); //TODO: how to know how many records are
    const [ totalCount, setTotalCount ] = useState<Number>(0);
    // const observer = useRef();
    
    const history = useHistory();
    const location: any = useLocation();
    const loggedInUserData = useContext(Context).user;

    const { t } = useTranslation();

    const [ storyComments, setStoryComments ] = useState<IGenericObject>({})

    //ref for story cleanup
    const queryStringRef = useRef<string>();
    useEffect(() => {
        queryStringRef.current = queryString;
    }, [queryString]);

    useEffect(() => {
        if(location.state && location.state.hasOwnProperty('filters') && location.state.filters.hasOwnProperty('story')) {
            //have to get some information from rerouting to be able to fill out query
            const newFilters = {...defaultStoryFilters, ...location.state.filters.story};
            setFilters(newFilters);
            setStateLoaded(true);
        } else {
            setStateLoaded(true)
        }
    }, []);

    useEffect(() => {
        //Only run automatic filtering if the state is already loaded.
        if(stateLoaded) {
            setupQueryString();
        }
    }, [filters, sortOption, stateLoaded ])

    useEffect(() => {
        if(stateLoaded && !loading) {
            fetchPublishedStories();
        }
    }, [queryString, filtersActive, pageNum])

    useEffect(() => {
        setPublishedStories([]); //Clear options;
        
        switch (sort){
            case sortOptions.PUBLISHED_DATE:
                setSortOption({field:'createdAt', type:'desc'})
                break
            case sortOptions.OLDEST:
                setSortOption({field:'date.startDate.date', type:'asc'})
                break
            case sortOptions.NEWEST:
                setSortOption({field:'date.startDate.date', type:'desc'})
                break
            default:
                setSortOption({field:'', type:''})
        }

    }, [ sort ])

    const [layout, setLayout] = useState<layoutStyle>(layoutStyle.DASHBOARD);


    async function fetchPublishedStories() {
        try {
            setLoading(true);
            //Get newly published stories
            const newStories = await instance.get(`/publishedstories?${queryString}&pageNum=${pageNum}`);
            setPublishedStories((prev) => { return [...prev, ...newStories.data.result]});
            setHasMore(newStories.data.hasNextPage);
            setTotalCount(newStories.data.totalRecords)
            setLoading(false);

        } catch (error) {
            console.log("Failed to get published stories")
        }
    }

    function getImage(story:IStory) {
        const featured = story.featured as IUserDetails

        return featured.avatar ? featured.avatar : default_avatar
    }

    function setShowFilters() {
        _setShowFilters(!showFilters)
    }

    function removeAllfilters() {
        setFilters(defaultStoryFilters);
    }

    /**
     * Delete a filter by specific key.
     * @param key - The key for the filter we want to delete
     */
    function removeFilterByKey(key:string) {
        let tempFilterOject = { ...filters }
        if(tempFilterOject.hasOwnProperty(key)) {
            tempFilterOject[key] = defaultStoryFilters[key];
            setFilters({...tempFilterOject})
        }
    }

    function filterRow() {
        let emptyFilters = true;
        const filterRow = Object.entries(filters).map(([key, value]) => {
            if(Array.isArray(value)) {
                if(!value.length) {
                    return
                }
            }

            if(key === "date") {
                if((value.year === null && value.month === null && value.day === null)) {
                    return
                }
            }
            if(value) {
                emptyFilters = false;
                return(
                    <div className="active-filter-box">
                        <div>
                            <Icon className="info-icon" size={0.7} path={filterIcons[key]} />
                        </div>
                        {Array.isArray(value) ?
                            (key === "featured" || key === "storyAuthorCoAuthor" || key === "participants" || key === "storiesRelatingTo" ) ? <p>{value.map(x =>`${x.firstname} ${x.lastname}`).join(", ")}</p> : <p>{value.map(x => x.text).join(",")}</p>
                            :
                            (value === true || value === false) ? <p>{booleanReadableValues[key]}</p> : 
                            (key === 'date') ? <p>{moment(value.date).format(`${value.year ? "D" : ""} ${value.month ? "MMM," : ""} ${value.year ? "YYYY" : ""}`)}</p> : 
                            (key === 'country') ?  <p>{(countries as any)[value] ? (countries as any)[value].name : value}</p>:
                            <p>{value}</p>
                        }
                        {/* <p>{key}</p> */}
                        <div className="remove" onClick={() => removeFilterByKey(key)}>
                            <Icon className="close-icon" size={0.7} path={mdiClose} />
                        </div>
                    </div>
                )
            }
        })

        return filterRow;
    }

    async function setupQueryString() {
        const refactoredFilters:IRefactoredQueryObject = {};

        let filtersActive = false;

        Object.entries(filters).forEach(function([key, value]) {

            if(Array.isArray(value)) {
                if(!value.length) {
                    return
                }
            }

            //Not add date if
            if(key === "date") {
                if(value.day === null && value.month === null && value.year === null) {
                    return;
                }
            }

            if(value) {
                if(key === "search") {
                    refactoredFilters[key] = value;
                }
                else {
                    filtersActive = true;
                    if(key === "lifeLessons" || key === "storyTags" || key === "accessGroups") {
                        refactoredFilters[key] = value.map((x:IOption) => x._id);
                    }
                    else if(key === "featured" || key === "participants" || key === "storyAuthorCoAuthor" || key === "storiesRelatingTo") {
                        if(!value.length) {
                            return
                        }
                        refactoredFilters[key] = value.map((x:IUserDetails) => x._id);
                    }
                    else if(key === 'readTime') {
                        const time = readTimeOptions.find((x) => x.key === value);
                        if(time) {
                            refactoredFilters[key] = time.searchValue;
                        }
                    }
                    else if(key === "date") {
                        let dates:any = []
        
                        if(value.year !== null) {
                            dates.push(value.year)
                        }
                        if(value.month !== null) {
                            dates.push(value.month)
                        }
                        if(value.day !== null) {
                            dates.push(value.day)
                        }
                        refactoredFilters[key] = dates
                    }
                    else {
                        refactoredFilters[key] = value
                    }
                }
            }
        })

        let sortString = "";

        if(sortOption.field.length) {
            sortString = "sort=" + sortOption.field + "," + sortOption.type;
        }
        const filterString =   Object.keys(refactoredFilters).map(function(key) {
            return key + '=' + refactoredFilters[key]
        }).join('&');

        const queryString = sortString + (filterString.length ? "&" + filterString : '')

        if(queryString.length) {
            setPageNum(0);
            setPublishedStories([]);
            setQueryString(queryString);
            if(filterString.length && filtersActive) {
                setFiltersActive(true);
            }
            else {
                setFiltersActive(false);
            }
        }
        else {
            setPageNum(0);
            setQueryString('');
            setPublishedStories([]);
            setFiltersActive(false);
            fetchPublishedStories();
        }
    }

    enum StoryType {
        NEW="0",
        UNREAD="1",
        FAVOURITE="2",
        OWN="3",
        ACTIVE="4",
        INACTIVE="5",
        REST="6",
    }

    function getBlockData(blocks:IBlock[], id:string) {
        const textBlock: IBlock | undefined = blocks.find(x => x.type === blockTypes.TEXT);
        const imageBlock: IBlock | undefined = blocks.find(x => x.type === blockTypes.IMAGE);
        const videoBlock: IBlock | undefined = blocks.find(x => x.type === blockTypes.VIDEO);
        const lifeLessonBlock: IBlock | undefined = blocks.find(x => x.type === blockTypes.LIFE_LESSON);
        const carouselBlock: IBlock | undefined = blocks.find(x => x.type === blockTypes.CAROUSEL);

        let refactoredText = "";
        if(textBlock) {
            const blocksFromHTML = convertFromHTML(textBlock.data);
            const state = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap,
            );
            const data = EditorState.createWithContent(state)
            refactoredText = data.getCurrentContent().getPlainText().replace('\n','') // getPlainText('\u0001')
        }
        // refactoredText = 300 is a fallback, a CSS trick provides 3 lines of text

        let refactoredLifeLesson = "";
        if(lifeLessonBlock) {
            const blocksFromHTML = convertFromHTML(lifeLessonBlock.data);
            const state = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap,
            );
            const data = EditorState.createWithContent(state)
            refactoredLifeLesson = data.getCurrentContent().getPlainText().replace('\n','') // getPlainText('\u0001')
        }        

        return(
            <div className="content">
                {lifeLessonBlock && !textBlock && !imageBlock && !videoBlock ? <div className="display-block-lifelesson">
                    <div className="icon">
                        <IconLifelesson />
                    </div>
                    <div className="container">                    
                        {refactoredLifeLesson}                
                    </div>                        
                </div> : null}
                {textBlock ? <p>{refactoredText.substring(0,300)}{refactoredText.length > 300 ? <span>... <a href={`/dashboard/story/${id}`}>More</a></span> : null} </p> : null}
                {/* {videoBlock && <div className="video-content" onClick={(e) => e.stopPropagation() }><VideoPlayer onClick={() => history.push(`/dashboard/story/${id}`)}  url={videoBlock.media?.data}/></div>}
                {(imageBlock && !videoBlock) ? <img alt={imageBlock._id} src={imageBlock.media?.dataM ? imageBlock.media?.dataM : imageBlock.media?.data} /> : null}
                {(carouselBlock && carouselBlock.multipleMedia.length) ? <img alt={carouselBlock.multipleMedia[0]._id} src={carouselBlock.multipleMedia[0].data}/> : null} */}
            </div>
        );

    }

    function displayStory(story: IStory, type: StoryType) {
        return (
            <StoryItem 
                key={story._id}
                story={story}
                type={type as any}
                onSelect={() => history.push(`/dashboard/story/${story._id}`)}
                onAction={(e) => { 
                    e.stopPropagation();
                    setSelected(story);
                }}
                onFlagClick={(e) => {
                    e.stopPropagation();
                    type === StoryType.NEW ? setShowNew(true) : setShowUnRead(true);
                }}
                flagText={type === StoryType.NEW ? t("Newly Published") : undefined}
                getBlockData={getBlockData}
                storyComments={storyComments}
                layout={layout === layoutStyle.SINGLE ? 'single' : 'dashboard'}
            />
        )
    }

    return(
        <div className="all-published-container">
            <StoryFiltersBar removeAllfilters={removeAllfilters} filtersActive={filtersActive} showFilters={showFilters} setShowFilters={setShowFilters} setSort={setSort} sort={sort} search={filters.search} setSearch={setFilters}/>
            {!showFilters ? 
                <div className='all-stories all-story-container'>
                    {filtersActive && <div className='active-filter-container'>{filterRow()}</div>}
                    
                    <div className="stories-header">
                        <p className="story-count">{totalCount} {t("Stories")}</p>
                        
                        <div className="layout-change">
                            <span onClick={() => setLayout(layoutStyle.SINGLE)}>
                                <Icon 
                                    className={`icon ${layout === layoutStyle.SINGLE ? "active" : ""}`} 
                                    size={1} 
                                    path={mdiViewSequential} 
                                />
                            </span>
                            <span onClick={() => setLayout(layoutStyle.DASHBOARD)}>
                                <Icon 
                                    className={`icon ${layout === layoutStyle.DASHBOARD ? "active" : ""}`} 
                                    size={1} 
                                    path={mdiViewDashboard} 
                                />
                            </span>
                        </div>
                    </div>

                    <div className={layout === layoutStyle.SINGLE ? "stories-list" : "stories-grid"}>
                        {layout === layoutStyle.DASHBOARD ? (
                            <Masonry
                                breakpointCols={{
                                    default: 3,
                                    1100: 2,
                                    700: 1
                                }}
                                className="my-masonry-grid"
                                columnClassName="my-masonry-grid_column"
                            >
                                {publishedStories.map((story:IStory) => (
                                    displayStory(story, StoryType.ACTIVE)
                                ))}
                            </Masonry>
                        ) : (
                            <div className="single-layout">
                                
                                {publishedStories.map((story:IStory) => (
                                    displayStory(story, StoryType.ACTIVE)
                                ))}
                            </div>
                        )}
                    </div>
                    {(!publishedStories.length && !loading) && <IFrameDisplay pageName={"stories-all"} />}
                    {selected && <StoryActionModal story={(selected as IStory)} author={((selected as IStory).author as IUserDetails)._id === loggedInUserData._id || (selected as IStory).coAuthors.map(x => (x as IUserDetails)._id).includes(loggedInUserData._id)} show={selected ? true : false} onHide={() => setSelected(false)} redirect={() =>  history.push('/dashboard/create', {data: (selected as IStory)})}/>}
                    {hasMore ? <button className="bordered-button" onClick={() => setPageNum(prev => prev+1)}> {loading ? <Spinner animation="border" variant="#fff" /> : t("Load more")}</button> : null}
                    {!hasMore && publishedStories.length ? <p className="has-more">{t("No more stories to display")}</p> : null}
                    <TooltipHelp
                        title={newTooltip.title}
                        subtitle={newTooltip.subtitle}
                        text={newTooltip.text}
                        show={showNew}
                        clear={() => setShowNew(false)}
                    />
                    <TooltipHelp
                        title={unReadTooltip.title}
                        subtitle={unReadTooltip.subtitle}
                        text={unReadTooltip.text}
                        show={showUnRead}
                        clear={() => setShowUnRead(false)}
                    />
                </div>
            :
                <StoryFilters setShowFilters={setShowFilters} queryString={queryString} filters={filters} setFilters={setFilters} setFiltersActive={setFiltersActive}/>}
        </div>
    );
}